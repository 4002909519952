<template>
	<div class="gadget board">
		<h3>공지사항</h3>
		<div class="tab-wrap">
			<ul class="tab-items">
				<li
					class="tab"
					v-for="(notice, index) in notices"
					:key="index"
					:class="{ on: index === selectedItemIdx }"
					@click="selectedMembership(index)"
				>
					<a> {{ notice.name }} </a>
				</li>
			</ul>
			<!-- contents 01 -->
			<div
				class="tab-contents"
				v-for="(notice, index) in notices"
				:key="index"
				:class="{ blind: index != selectedItemIdx }"
			>
				<ul v-for="(article, idx) in notice.data" :key="idx">
					<li>
						<a :href="article.url">{{ article.title }}</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { accountNotices, manualNotices, portalNotices } from '@/api/carelounge';

export default {
	data() {
		return {
			selectedItemIdx: '',
			notices: [],
		};
	},
	async created() {
		const manualList = await manualNotices();
		const accountList = await accountNotices();

		this.notices.push({
			name: '그룹웨어',
			data: manualList,
		});
		this.notices.push({
			name: '경리회계',
			data: accountList,
		});
		this.selectedMembership(0);
	},
	methods: {
		selectedMembership(idx) {
			this.selectedItemIdx = idx;
		},
	},
};
</script>

<style scoped></style>
