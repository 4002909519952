<!--fixedPayMethod="usePayMethod ? '' : 'deposit'"-->
<template>
	<Fragment>
		<div>
			<carousel
				class="info-carousel"
				:perPage="1"
				:navigationEnabled="memberships.length > 1 ? true : false"
				:autoplay="true"
				:loop="true"
				:autoplayTimeout="5000"
				paginationActiveColor="#00A1B9"
				paginationColor="#ddd"
				:paginationPadding="4"
				:navigationClickTargetSize="0"
			>
				<slide v-for="(membership, idx) in memberships" :key="idx" tabindex="1" class="info-slide">
					<!-- 컨텐츠 상세 01 -->
					<div class="membership-card">
						<!-- contents wrap -->
						<div class="membership-contents-wrap">
							<a
								@click="() => $router.push(`/user/myStore/MembershipStatus?membershipId=${membership.id}`)"
								class="membership-contents"
							>
								<!-- state badge -->
								<div class="badge body-2-b">{{ membershipStatusMap[membership.status] }}</div>
								<!-- badge case -->
								<!-- <div class="badge caution body-2-b">초과 사용중</div> -->
								<!-- <div class="badge termination body-2-b">해지</div> -->
								<!-- title22 -->
								<h4 class="heading-3-b">
									{{ membership.membershipName }}
									<i class="ic-16 ic-arr-right white"></i>
								</h4>
								<!-- duration -->
								<p class="membership-duration">
									{{ totUsePeriod(membership) }}
									<em class="caution body-1-b" v-if="membership.remainDate.totalRemainDay < 30"
										>(만료 {{ membership.remainDate.totalRemainDay }}일전)</em
									>
								</p>
								<!-- membership info -->
								<table class="membership-info">
									<colgroup>
										<col />
										<col />
										<col />
										<col />
									</colgroup>
									<thead>
										<tr>
											<!--											<th>부가 서비스</th>-->
											<th>옵션 상품</th>
											<th>사용자</th>
											<th>계약용량</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<!--											<td>부가서비스?</td>-->
											<td>{{ membership.baseChannelInfoModel.usingChannelItemModels.length }} 개</td>
											<td>{{ membership.baseChannelInfoModel.totalUserCount }}인</td>
											<td>{{ membership.baseChannelInfoModel.totalQuota }}GB</td>
										</tr>
									</tbody>
								</table>
							</a>
							<!-- button -->
							<button class="solid" @click="() => $router.push(`/order/addExtension?membershipId=${membership.id}`)">
								{{ membership.membershipChargeType === 'FREE' ? '유료 전환 / 상품 추가주문' : '추가/연장 주문하기' }}
							</button>
						</div>
						<!-- summary -->
						<template v-if="membership.partnerSimpleModel.salesType === 'RESELLER'">
							<!-- 리셀러 -->
							<div class="membership-contents-summary">
								<p class="title heading-3-b">
									계약 만료일은 <br />
									<em>{{ membership.membershipBaseInfoModel.expirationDate }}</em
									>입니다.
								</p>
							</div>
						</template>
						<template v-else-if="membership.membershipChargeType === 'FREE'">
							<!-- 무료 -->
						</template>
						<template v-else-if="membership.autoExtension">
							<!-- 자동결제 -->
							<div class="membership-contents-summary">
								<p class="title heading-3-b">
									결제 예정금액은 <br />
									<em>{{ membership.monthlyFee | currency }}원</em>입니다.
								</p>
							</div>
						</template>
						<template v-else>
							<!-- 마지막 -->
							<div class="membership-contents-summary">
								<p class="title heading-3-b">
									월 사용료는 <br />
									<em>{{ membership.monthlyFee | currency }}원</em>입니다.
								</p>
								<div>
									<p class="description">
										계약은 30일 전 서면으로 변경/해지 통보가 없을 시 1년 단위로 자동 연장됩니다.
									</p>
									<div class="date">
										<p class="body-3-r">계약 만료일</p>
										<p class="body-1-m">{{ membership.membershipBaseInfoModel.expirationDate }}</p>
									</div>
								</div>
							</div>
						</template>
						<!-- 1. 무료 사용 고객은 .membership-contents-summary 영역 자체를 사용하지 않음 -->
						<!--						<div class="membership-contents-summary">-->
						<!--							&lt;!&ndash; 2. 정기 결제 케이스 &ndash;&gt;-->
						<!--							<template v-if="membership.autoExtension">-->
						<!--								<p class="title heading-3-b">-->
						<!--									결제 예정금액은 <br />-->
						<!--									<em>{{ membership.monthlyFee | currency }}원</em>입니다.-->
						<!--								</p>-->
						<!--							</template>-->
						<!--							&lt;!&ndash; 3. 일반 결제 케이스 &ndash;&gt;-->
						<!--							<template v-else>-->
						<!--								<p class="title heading-3-b">-->
						<!--									월 사용료는 <br />-->
						<!--									<em>{{ 0 | currency }}원</em>입니다.-->
						<!--								</p>-->
						<!--							</template>-->
						<!--							&lt;!&ndash; 4. 리셀러 고객 케이스-->
						<!--							<p class="title heading-3-b">-->
						<!--								계약 만료일은 <br />-->
						<!--								<em>23년 4월 30일</em>입니다.-->
						<!--							</p> -->
						<!--							// [D] 리셀러 고객 케이스에서는 아래 date/detail 메시지 영역 사용 안함  &ndash;&gt;-->
						<!--							<template v-if="membership.autoExtension">-->
						<!--								<div class="date">-->
						<!--									<p class="body-3-r">다음 결제 예정일</p>-->
						<!--									<p class="body-1-m">{{ membership.membershipBaseInfoModel.expirationDate }}</p>-->
						<!--								</div>-->
						<!--							</template>-->
						<!--							<template v-else>-->
						<!--								<div>-->
						<!--									<p class="description">-->
						<!--										계약은 30일 전 서면으로 변경/해지 통보가 없을 시 1년 단위로 자동 연장됩니다.-->
						<!--									</p>-->
						<!--									<div class="date">-->
						<!--										<p class="body-3-r">계약 만료일</p>-->
						<!--										<p class="body-1-m">{{ membership.membershipBaseInfoModel.expirationDate }}</p>-->
						<!--									</div>-->
						<!--								</div>-->
						<!--							</template>-->
						<!--						</div>-->
					</div>
				</slide>
			</carousel>
		</div>
	</Fragment>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
import { membershipStatusMap } from '@/utils/constants';

export default {
	name: 'membership-card',
	components: { Carousel, Slide },
	data() {
		return {
			memberships: [],
			membershipStatusMap: membershipStatusMap,
		};
	},
	methods: {
		totUsePeriod(membership) {
			const fromDate = this.$moment(membership.membershipBaseInfoModel.startDate);
			const toDate = this.$moment(membership.membershipBaseInfoModel.expirationDate).format('YYYY-MM-DD');
			const today = this.$moment();
			if (today.isAfter(fromDate)) {
				return today.format('YYYY-MM-DD') + '~' + toDate;
			} else {
				return fromDate.format('YYYY-MM-DD') + '~' + toDate;
			}
		},
	},
	async mounted() {
		this.memberships = await this.$store.dispatch('fetchMemberships');
	},
};
</script>
<style>
@import '../../assets/css/common.css';
@import '../../assets/css/main.css';
@import '../../assets/css/vue-carousel.css';
</style>
