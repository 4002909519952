<template>
	<div class="container">
		<div class="body-contents">
			<div class="section-left">
				<!-- 메인 배너 -->
				<!-- 멤버십 정보 노출 구분 시작 -->
				<!-- 정회원시 노출 -->
				<div v-if="isRegularCustomer || isRegularIndividual" class="membership main-cont">
					<h3>내 멤버십 현황</h3>
					<div class="carousel">
						<!-- 컨텐츠 위치 : ../../vendor/vue-carousel-sample.html -->
						<!--
                                            **** Template 옵션 ***
                                            1. :navigationEnabled="true"
                                            2. :perPage="1"
                                            3. :autoplay="true"
                                            4. :autoplayTimeout="3000"
                                            5. :paginationActiveColor="#00A1B9"
                                            6. :paginationColor="#ddd"
                                            7. :paginationPadding="4"
                                            8. :navigationClickTargetSize="0"
                                        -->
						<membership-card />
					</div>
				</div>
				<!-- 준회원시 노출 -->
				<div v-else class="service-notice main-cont">
					<p class="main-text">
						환영합니다! <br />
						원하는 요금제를 빠르게 개설해보세요.
					</p>
					<div class="link-banner group row gap16">
						<a class="goto-order free" @click="$router.push('/apply/step1?')">
							<p class="heading-3-b">
								10인 미만 회사라면 완전 무료 <br />
								<em class="text-primary">공유형 무료</em>
							</p>
							<p class="goto body-2-b">서비스 신청<i class="ic-16 ic-arr-goto"></i></p>
						</a>
						<a class="goto-order payable" @click="$router.push('/apply/step1?servicetype=SAAS')">
							<p class="heading-3-b">
								주문 즉시 서비스 사용가능! <br />
								<em class="text-primary">공유형 유료</em>
							</p>
							<p class="goto body-2-b">서비스 신청<i class="ic-16 ic-arr-goto"></i></p>
						</a>
					</div>
				</div>
				<!-- 멤버십 정보 노출 구분 끝 -->
				<!-- 고객케어 라운지 링크 -->
				<div class="link-area">
					<h3>자주 묻는 질문</h3>
					<ul class="link-list">
						<!-- @0510 new -->
						<li>
							<a
								href="https://myfaq.daouoffice.co.kr/hc/ko/sections/18099306877977"
								target="_blank"
								class="link-goto text-center"
							>
								<i class="ic-40 ic-bill"></i>
								<h4 class="body-1-m text-primary"># 서비스 가입 / 계약 / 해지</h4>
							</a>
						</li>
						<li>
							<a
								href="https://myfaq.daouoffice.co.kr/hc/ko/sections/18101228369689"
								target="_blank"
								class="link-goto text-center"
							>
								<i class="ic-40 ic-card"></i>
								<h4 class="body-1-m text-primary"># 결제 / 환불</h4>
							</a>
						</li>
						<li>
							<a
								href="https://myfaq.daouoffice.co.kr/hc/ko/sections/18101218770841"
								target="_blank"
								class="link-goto text-center"
							>
								<i class="ic-40 ic-cart"></i>
								<h4 class="body-1-m text-primary"># 상품 추가 / 서비스 연장</h4>
							</a>
						</li>
						<li>
							<a
								href="https://myfaq.daouoffice.co.kr/hc/ko/sections/18101251995673"
								target="_blank"
								class="link-goto text-center"
							>
								<i class="ic-40 ic-guide"></i>
								<h4 class="body-1-m text-primary"># 초기 설정 / 서비스 가이드</h4>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="section-right">
				<!-- gadget 1 -->
				<div class="gadget welcome-msg">
					<div class="msg-area">
						<!-- @정회원 or 법인 정보는 등록한 준회원도 가능-->
						<h2 class="title" v-if="this.companyInfo && currentUser.levelType === 'LEVEL2'">
							<p>
								<em class="point">
									<!-- {{ $회사 이름 }}-->
									{{ companyInfo.corporateName }}</em
								>
								<span class="name">
									<!-- {{ $사용자 이름 }}-->
									{{ currentUser.name }}님,</span
								>
								<span>안녕하세요!</span>
								<i class="ic-48 ic-hand"></i>
							</p>
						</h2>
						<!-- @준회원 중 법인 정보 등록하지 "않은" -->
						<div v-else>
							<h2 class="title">
								<p>
									<em class="point">
										<!-- {{ $회사 이름 }}-->
									</em>
									<span class="name">
										<!-- {{ $사용자 이름 }}-->
										{{ currentUser.name }}님,</span
									>
									<span>안녕하세요!</span>
									<i class="ic-48 ic-hand"></i>
								</p>
							</h2>
						</div>
					</div>
					<div class="banner-area">
						<img :src="homeBannerImg()" @click="homeBannerLink" />
						<!-- <p class="title">
                          {{ getPromotionAdvertisementApplyHead() }}
                          <em class="point">
                            {{ getPromotionAdvertisementApplyBody() }}
                          </em>
                        </p>
                        <p
                          class="text-goto"
                          @click="
                            () => {
                              this.$router.push('/apply/step1');
                            }
                          "
                        >
                          바로가기<i class="ic-16 ic-next"></i>
                        </p> -->
						<!--						</a>-->
					</div>
				</div>
				<!-- gadget 2 -->
				<care-lounge-notice></care-lounge-notice>
			</div>
		</div>
	</div>
</template>
<script>
import MemberShipCard from '@/views/home/MemberShipCard.vue';
import { promotion } from '@/views/common/promotion.js';
import CareLoungeNotice from '@/views/home/CareLoungeNotice';
import { CARELOUNGEURL } from '@/constants';

export default {
	name: 'Home',
	mixins: [promotion],
	computed: {
		portalCareLoungeUrl() {
			return CARELOUNGEURL;
		},
		// regular: 정회원, associate: 준회원
		isRegularCustomer() {
			return this.$store.getters['isMemberShipUser'] && this.$store.getters['isSuperAdmin'];
		},
		isRegularIndividual() {
			return this.$store.getters['isMemberShipUser'] && !this.$store.getters['isSuperAdmin'];
		},
		isAssociateCustomer() {
			return !this.$store.getters['isMemberShipUser'] && this.$store.getters['isSuperAdmin'];
		},
		isAssociateIndividual() {
			return !this.$store.getters['isMemberShipUser'] && !this.$store.getters['isSuperAdmin'];
		},
	},
	data() {
		return {
			isMembership: this.$store.getters['isMemberShipUser'],
			currentUser: this.$store.state.login.currentUser,
			companyInfo: null,
			homeBanner: {
				imgUrl: '',
				link: '/apply/step1?servicetype=SAAS',
			},
		};
	},
	async created() {
		const customer = await this.$store.dispatch('fetchCustomerInfo').catch(() => {});
		this.companyInfo = customer?.data.companyInfo;
		await this.getTodayPromotions()
			.then(() => {
				this.homeBanner = this.$store.getters.getHomeBanner;
			})
			.catch(() => {});
	},
	components: {
		CareLoungeNotice,
		'membership-card': MemberShipCard,
	},
	methods: {
		homeBannerImg() {
			return this.homeBanner.imgUrl;
		},
		homeBannerLink() {
			window.open(this.homeBanner.link, '_blank');
		},
	},
};
</script>
<style>
@import '../assets/css/common.css';
@import '../assets/css/main.css';
</style>
