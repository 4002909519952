var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"body-contents"},[_c('div',{staticClass:"section-left"},[(_vm.isRegularCustomer || _vm.isRegularIndividual)?_c('div',{staticClass:"membership main-cont"},[_c('h3',[_vm._v("내 멤버십 현황")]),_c('div',{staticClass:"carousel"},[_c('membership-card')],1)]):_c('div',{staticClass:"service-notice main-cont"},[_vm._m(0),_c('div',{staticClass:"link-banner group row gap16"},[_c('a',{staticClass:"goto-order free",on:{"click":function($event){return _vm.$router.push('/apply/step1?')}}},[_vm._m(1),_vm._m(2)]),_c('a',{staticClass:"goto-order payable",on:{"click":function($event){return _vm.$router.push('/apply/step1?servicetype=SAAS')}}},[_vm._m(3),_vm._m(4)])])]),_vm._m(5)]),_c('div',{staticClass:"section-right"},[_c('div',{staticClass:"gadget welcome-msg"},[_c('div',{staticClass:"msg-area"},[(this.companyInfo && _vm.currentUser.levelType === 'LEVEL2')?_c('h2',{staticClass:"title"},[_c('p',[_c('em',{staticClass:"point"},[_vm._v(" "+_vm._s(_vm.companyInfo.corporateName))]),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.currentUser.name)+"님,")]),_c('span',[_vm._v("안녕하세요!")]),_c('i',{staticClass:"ic-48 ic-hand"})])]):_c('div',[_c('h2',{staticClass:"title"},[_c('p',[_c('em',{staticClass:"point"}),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.currentUser.name)+"님,")]),_c('span',[_vm._v("안녕하세요!")]),_c('i',{staticClass:"ic-48 ic-hand"})])])])]),_c('div',{staticClass:"banner-area"},[_c('img',{attrs:{"src":_vm.homeBannerImg()},on:{"click":_vm.homeBannerLink}})])]),_c('care-lounge-notice')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"main-text"},[_vm._v(" 환영합니다! "),_c('br'),_vm._v(" 원하는 요금제를 빠르게 개설해보세요. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"heading-3-b"},[_vm._v(" 10인 미만 회사라면 완전 무료 "),_c('br'),_c('em',{staticClass:"text-primary"},[_vm._v("공유형 무료")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"goto body-2-b"},[_vm._v("서비스 신청"),_c('i',{staticClass:"ic-16 ic-arr-goto"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"heading-3-b"},[_vm._v(" 주문 즉시 서비스 사용가능! "),_c('br'),_c('em',{staticClass:"text-primary"},[_vm._v("공유형 유료")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"goto body-2-b"},[_vm._v("서비스 신청"),_c('i',{staticClass:"ic-16 ic-arr-goto"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"link-area"},[_c('h3',[_vm._v("자주 묻는 질문")]),_c('ul',{staticClass:"link-list"},[_c('li',[_c('a',{staticClass:"link-goto text-center",attrs:{"href":"https://myfaq.daouoffice.co.kr/hc/ko/sections/18099306877977","target":"_blank"}},[_c('i',{staticClass:"ic-40 ic-bill"}),_c('h4',{staticClass:"body-1-m text-primary"},[_vm._v("# 서비스 가입 / 계약 / 해지")])])]),_c('li',[_c('a',{staticClass:"link-goto text-center",attrs:{"href":"https://myfaq.daouoffice.co.kr/hc/ko/sections/18101228369689","target":"_blank"}},[_c('i',{staticClass:"ic-40 ic-card"}),_c('h4',{staticClass:"body-1-m text-primary"},[_vm._v("# 결제 / 환불")])])]),_c('li',[_c('a',{staticClass:"link-goto text-center",attrs:{"href":"https://myfaq.daouoffice.co.kr/hc/ko/sections/18101218770841","target":"_blank"}},[_c('i',{staticClass:"ic-40 ic-cart"}),_c('h4',{staticClass:"body-1-m text-primary"},[_vm._v("# 상품 추가 / 서비스 연장")])])]),_c('li',[_c('a',{staticClass:"link-goto text-center",attrs:{"href":"https://myfaq.daouoffice.co.kr/hc/ko/sections/18101251995673","target":"_blank"}},[_c('i',{staticClass:"ic-40 ic-guide"}),_c('h4',{staticClass:"body-1-m text-primary"},[_vm._v("# 초기 설정 / 서비스 가이드")])])])])])
}]

export { render, staticRenderFns }