import { portalCareloungeAPI, menualCareloungeAPI, accountCareloungeAPI } from './index';

export const portalNotices = async () => {
	const notices = [];

	try {
		const { data } = await portalCareloungeAPI.get(
			`/api/v2/help_center/ko/sections/16930543066905/articles?per_page=4`,
		);
		data.articles.forEach((item) => {
			notices.push({
				id: item.id,
				title: item.title,
				url: item.html_url,
			});
		});
	} catch (e) {
		console.error(`api call error`);
	}

	return notices;
};

export const manualNotices = async () => {
	const notices = [];

	try {
		const { data } = await menualCareloungeAPI.get(`/api/v2/help_center/ko/sections/115000843293/articles?per_page=4`);
		data.articles.forEach((item) => {
			notices.push({
				id: item.id,
				title: item.title,
				url: item.html_url,
			});
		});
	} catch (e) {
		console.error(`api call error`);
	}

	return notices;
};

export const accountNotices = async () => {
	const notices = [];

	try {
		const { data } = await accountCareloungeAPI.get(
			`/api/v2/help_center/ko/sections/4404573298841/articles?per_page=4`,
		);
		data.articles.forEach((item) => {
			notices.push({
				id: item.id,
				title: item.title,
				url: item.html_url,
			});
		});
	} catch (e) {
		console.error(`api call error`);
	}

	return notices;
};
